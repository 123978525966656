import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "tableBody", "exportLink"]

  connect() {
    console.log("Client Positions controller connected")
  }

  filter(event) {
    event.preventDefault()
    console.log("Filter called")
    
    const formData = new FormData(event.target)
    const queryString = new URLSearchParams(formData).toString()
    
    // Update URL with new parameters
    window.history.pushState({}, '', `${window.location.pathname}?${queryString}`)
    
    // Update export links
    this.exportLinkTargets.forEach(link => {
      const url = new URL(link.href)
      formData.forEach((value, key) => {
        url.searchParams.set(key, value)
      })
      link.href = url.toString()
    })

    fetch(`/li_log_events?${queryString}`, {
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(response => response.json())
    .then(events => {
      this.tableBodyTarget.innerHTML = events.map(event => `
        <tr>
          <td class="px-6 py-4 whitespace-nowrap">${event.linkedin_message}</td>
          <td class="px-6 py-4 whitespace-nowrap">${event.position}</td>
          <td class="px-6 py-4 whitespace-nowrap">${event.client}</td>
          <td class="px-6 py-4 whitespace-nowrap">${event.candidate}</td>
          <td class="px-6 py-4 whitespace-nowrap">
            <a href="${event.recruiter.url}" class="text-indigo-600 hover:text-indigo-900">
              ${event.recruiter.name}
            </a>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">${event.date}</td>
          <td class="px-6 py-4 whitespace-nowrap">
            <a href="${event.show_url}" class="text-indigo-600 hover:text-indigo-900">Show</a>
          </td>
        </tr>
      `).join('')
    })
    .catch(error => console.error("Error:", error))
  }

  loadPositions(event) {
    console.log("loadPositions called")
    const clientId = event.target.value
    if (clientId) {
      fetch(`/li_log_events/positions?client_id=${clientId}`, {
        headers: {
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
        .then(response => response.json())
        .then(positions => {
          this.selectTarget.innerHTML = '<option value="">All Positions</option>'
          positions.forEach(position => {
            this.selectTarget.innerHTML += `<option value="${position.id}">${position.name}</option>`
          })
        })
        .catch(error => {
          console.error("Error loading positions:", error)
        })
    } else {
      this.selectTarget.innerHTML = '<option value="">All Positions</option>'
    }
  }
} 